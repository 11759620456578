<!--
 * @Author: your name
 * @Date: 2020-09-04 11:43:43
 * @LastEditTime: 2025-03-20 19:19:44
 * @LastEditors: crm
 * @Description: In User Settings Edit
 * @FilePath: \workbench-front\src\views\workbench\order\after-sale\drop-out\index.vue
-->
<template>
  <div class="drop-out">
    <!-- <p>申请售后</p> -->
    <div class="drop-out-form-wrapper">
      <el-form :model="dropoutForm" :rules="drpoutFormRules" ref="dropoutForm">
        <section class="drop-out-form">
          <h2 class="sub-title">申请售后</h2>
          <el-form-item prop="saleType" style="width: 282px">
            <span>售后类型</span>
            <v-text-field
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
              value="退费"
            ></v-text-field>
          </el-form-item>
          <!-- 订单详情表格 -->
          <order :orderTable="orderTable" />
           <!-- 出纳审核及申请原因 -->
           <cashier :dropoutForm.sync="dropoutForm" :cashierCheckList="cashierCheckList" />
           <!-- 附件 -->
           <annex ref="annexRef"/>
        </section>
        
        <div class="line"></div>

        <!-- 退费信息 -->
        <div class="payinfo-wrapper">
          <h2 class="sub-title">付款信息</h2>
          <!-- 退费计算 收支项目 -->
          <charge-items :dropOutTableList="dropOutTableList" :classInfo="classInfo" :orderExtraInfo="orderExtraInfo" @addDropOut="addDropOut"/>
          <span>退费结算</span>
          <el-row style="margin-top: 12px">
            <el-col :span="4" style="width: 280px;">
              <div>
                <span class="fb14 color_title">合计扣费金额</span>
                <el-tooltip effect="light" content="售后订单扣减的金额总和，扣减的金额不用于退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>
              
              <section class="dealInfoBox fb14 color_price">{{allDeductionMoney | decimal2}} 元</section>
            </el-col>
            <el-col :span="4" style="width: 280px;margin-left: 24px">
              <div>
                <span class="fb14 color_title">{{ approvedRefundLabel }}</span>
                <el-tooltip effect="light" content="售后订单扣减后剩余的金额总和，可用于贷款平台退费和学员退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>

              <section class="dealInfoBox fb14 color_price">{{approvedRefundableAmount | decimal2}} 元</section>
            </el-col>
          </el-row>
          <el-form-item>
            <span>贷款退费<span class="start">*</span></span>
            <CheckBox
              style="margin-left: -10px"
              :list="[{label: '是', value: 1}, {label: '否', value: 0}]"
              v-bind:select.sync="dropoutForm.isLoanRefund"
              :disabled="[installmentModeEnum.onlineLoan, installmentModeEnum.offlineLoan].includes(installmentMode)"
              ref="check"
            />
          </el-form-item>
          <!-- 贷款  [installmentModeEnum.onlineLoan, installmentModeEnum.offlineLoan].includes(installmentMode)-->
          <loan-info 
            :loanInfoForm="dropoutForm" 
            :installmentMode="installmentMode" 
            :loanRefundStuMoney="loanRefundStuMoney" 
            :rules="drpoutFormRules" 
            :loanIouNoDisabled="loanIouNoDisabled" 
            :orderTable="orderTable" 
            :loanPlatformList="loanPlatformList"
            :loanRefundMoneyVerify="loanRefundMoneyVerify"
            v-if="dropoutForm.isLoanRefund" />

          <el-row style="">
            <el-col :span="4" style="width: 280px;margin:0">
              <span>学员退费<span class="start">*</span></span>
            </el-col>
            <el-col :span="4" style="width: 280px;margin:0;margin-left: 24px"  v-if="this.$store.state.user.loginUser.roleList[0].roleCode === 'XQXZ' || this.$store.state.user.loginUser.roleList[0].roleCode === 'GZT-SALES-MANAGER' || this.$store.state.user.loginUser.roleList[0].roleCode === 'ADMIN' || this.loginUser.roleList[0].roleCode === 'SUPER_ADMIN'">
              <span>总部退费<span class="start">*</span></span>
              <el-tooltip effect="light" content="总部退回校区余额" placement="top-start">   
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
            </el-col>
          </el-row>

          <el-row style="margin-top: 12px">
            <el-col :span="4" style="width: 280px;">
              <!-- 学员退费 -->
              <div class="fb14 color_title">退费金额</div>
              <section class="dealInfoBox fb14 color_price" style="margin-right: 20px;display: inline-block">{{loanRefundStuMoney | decimal2}} 元</section>
              <span v-if="loanRefundStuMoney < 0" class="approvedRefundTxt"><i class="el-icon-warning"></i>原订单需要学员补款，暂不支持售后申请</span>
            </el-col>

            <el-col :span="4" style="width: 280px;margin-left: 24px" v-if="this.$store.state.user.loginUser.roleList[0].roleCode === 'XQXZ' || this.$store.state.user.loginUser.roleList[0].roleCode === 'GZT-SALES-MANAGER' || this.$store.state.user.loginUser.roleList[0].roleCode === 'ADMIN' || this.loginUser.roleList[0].roleCode === 'SUPER_ADMIN'">
              <!-- 总部退费 -->
              <div class="fb14 color_title">退费金额</div>
              <section class="dealInfoBox fb14 color_price" style="margin-right: 20px;display: inline-block">{{returnCampusTotalAmount | decimal2}} 元</section>
            </el-col>
          </el-row>

          <!-- 付款方式 -->
          <payway v-if="loanRefundStuMoney !== 0" @checkPayTypeChange="checkPayTypeChange" :orderTable="orderTable" :dropoutForm="dropoutForm" :dropInfo="dropInfo" :disabledChangeDropOrderNo="disabledChangeDropOrderNo"/>
        </div>
      </el-form>
      <div class="line"></div>

      <div class="action-btns">
        <el-button type="plain" @click="doBack">返回</el-button>
        <el-button type="primary" @click="showSureData('dropoutForm')" :loading="submitLoading">提交</el-button>
      </div>
    </div>

    <order-modal
      :visible="orderModalVisible"
      :aftersaleType="0"
      :orderModalFields="orderModalFields"
      :orderModalItems="orderModalItems"
      :orderModalRules="orderModalRules"
      selectMode="0"
      @on-cancel="onOrderModalCancel"
      @on-ok="onOrderModalOk"
    />

    <!-- 选中收支项目 -->
    <receiveOut
      :visible="receiveOutVisible"
      :aftersaleType="2"
      :orderModalFields="receiveOutFields"
      :orderModalItems="receiveOut"
      :orderModalRules="orderModalRules"
      :currentSelDrop="receiveSelItem"
      @on-cancel="onReceiveOutCancel"
      @on-ok="onReceiveOutOk"
    />

    <!-- 提交时确认订单弹窗 -->
    <sure 
      :visible="sureVisible"
      showTitle="确认退费申请"
      :sureData="sureData"
      :loading="loading"
      @on-cancel="onSureCancel"
      @on-ok="onSureOk"
    />  

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { allPayMap, payTypeMap, orderStatusMap, afterSaleStatusMap  ,installmentTypeMap, refundPayTypeMap, refundPayTypeEnum, installmentTypeEnum, goodsTemplateEnum, mockCashierChecker, aftersaleTypeEnum, installmentModeEnum, OrderOriginTypeEnum, refundTypeEnum } from "../../constants"
import { accAdd, accSub } from "@/utils/mathMethod"
import common from '@/views/order/after-sale/mixins/common'
import { getSchoolProperties, getSchoolUserCashierVerifiedUser } from '@/api/customer';
import Checker from '@/utils/Checker';
export default {
  name: "OrderDropOut",
  mixins: [common],
  data() {
    const orderTableValidator = (rule, value, cb) => {
      if (!this.orderTable.length) {
        return cb(new Error("请选择订单"))
      }
      cb()
    }
    //付款方式必选
    const payMethd = (rule, value, cb) => {
      if (this.dropoutForm.payType === null || typeof this.dropoutForm.payType === 'undefined') {
        return cb(new Error("请选择订单"))
      }
      cb()
    }
    return {
      cashierCheckList: [],
      showloanform: false,//贷款本金显示
      sureVisible:false,//提交时确认弹出
      sureData:{ },
      installmentTypeMap ,
      receiveOutVisible: false, //收支项目框
      // receiveItemData: [], //收费项目
      tmpRow: null, //临时存要删除的订单
      receiveOut:[
        { label: "收支项目类型",type:"select", prop: "type", itemStyle: "", style: "", placeholder: "请选择",
          options:[{
            value: '代收代付',
            label: '代收代付'
          },{
            value: '营业收入',
            label: '营业收入'
          },{
            value: '其他',
            label: '其他'
          },{
            value: '其他收入',
            label: '其他收入'
          }] 
        },
        { label: "收支项目名称", prop: "name", itemStyle: "", style: "", placeholder: "请填写" },
        { label: "收支项目编码", prop: "code", itemStyle: "", style: "", placeholder: "请填写" },
      ],
      receiveOutFields: {
        select: "",
        name: "",
        code: ""
      },
      breadcrumbs: [
        {
          title: "订单售后管理",
          path: "/aftersale/list",
        },
        {
          title: "退学退费",
        },
      ],
      //退款时选择学员余额
      dropInfo:{
        dropOrderNo:"",
        dropMobile:"",
        dropName:"",//学员余额退款时的姓名
      },
      dropoutForm: {
        cashierId: '',//出纳审核人id
        aftersaleType: "0",
        reason: "",
        payType: "1",
        bankAccountName: "",
        bank: "",
        bankAccount: "",
        name: "",
        idCard: "",
        stuMoblie: "",
        userId: "",
        // 有钱花贷款 http://yapi.hqbis.com/project/156/interface/api/13066
        loanRefundTime: '',
        loanCompanyName: '',
        loanBankName: '',
        loanBankAccount: '',
        loanRefundMoney: '',
        loanIouNo: '',
        loanReturnedMoney: '',
        // 有钱花贷款 end
        // 付款银行信息:start
        ncAccountName: '',
        ncBankAccountPk: '',
        // 付款银行信息:end
        dropOrderNo:"",
        dropMobile:"",
        dropName:"",//学员余额退款时的姓名
        appShop: '',
        appName: '',
        ohterName: '',
        // 是否第三方
        payMode: '',
        // 是否贷款退费 0:否，1：是
        isLoanRefund: 0,
        loanOccupiedinterestMoney: 0,
        refundPayType: "",
      },

      loanIouNoDisabled: false,//禁止修改贷款借据号
      drpoutFormRules: {},
      orderTable: [],
      dropOutTableList: [],
      tmpDropOutTable:[],
      // 订单弹窗显隐
      orderModalVisible: false,
      orderModalFields: {
        orderNo: "",
        goodsName: "",
        stuName: "",
        mobile: "",
        idCard: "",
        customerCode: "",
        productId: ""
      },
      orderModalItems: [
        { label: "产品线", prop: "productId", type: "select", options: []},
        { label: "报名单号", prop: "orderNo", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "商品ID", prop: "goodsId", itemStyle: "", type: "text", style: "", placeholder: "请填写" },
        { label: "报读商品", prop: "goodsName", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "学员姓名", prop: "stuName", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "学员账号", prop: "mobile", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "客户编号", prop: "customerCode", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
        { label: "身份证号", prop: "idCard", itemStyle: "", type: "text", style: "", placeholder: "请输入" },
      ],
      orderModalRules: {},
      // 学员信息
      studentInfo: {
        mobile: "",
      },
      // 选择的订单的id数组
      ids: [],
      allPayMap,
      payTypeMap,
      orderStatusMap,
      afterSaleStatusMap,
      classInfo :[], //退费信息  退费计算的商品，已收上课等信息
      loading: false,
      orderExtraInfo: {},
      receiveSelItem:null,//添加收支项目时当前的收支项目
      afterSaleId: this.$route.query.afterSaleId,
      useDraft:true,//是否使用了草稿的内容，草稿内容只用一次，防止getDetailData一直走草稿if
      loanArray: [], //退费计算
      dropList: [],
      refundPayTypeEnum,
      installmentTypeEnum,
      goodsTemplateEnum,
      submitLoading: false,
      installmentModeEnum,
      disabledChangeDropOrderNo: false,
      // 出纳是否需要必填
      cashierFormRules: {
        reason: [{required: true,message: "此项必填！！",tirgger: "submit" }],
      },
      loanPlatformList: [],
      // 合计扣费金额
      allDeductionMoney: 0,
      cashierId: "",
      goodsTemplate: "",
      returnCampusTotalAmount: 0,
    }
  },
  components: {
    orderModal: () => import("../../components/order-modal"),
    receiveOut: () => import("../../components/receive-out"),
    sure: () => import("../../components/sure1"),
    CheckBox: () => import("components/check-box"),
    order: ()=> import('./components/order.vue'),
    cashier: ()=> import('./components/cashier.vue'),
    payway: ()=> import('./components/payway.vue'),
    chargeItems: ()=> import('./components/chargeItems.vue'),
    loanInfo: ()=> import('./components/loanInfo.vue'),
    annex: ()=>import('./components/annex.vue'),
  },
  methods: {
    // 获取总部退费金额
    async getBalanceReturnCampus(){
      const params = {
        orderId: this.orderId,
        aftersaleInoutProjectList: this.dropOutTableList[0], 
      }
      const { data, code } = await this.$fetch("getBalanceReturnCampus", params)
      if (code == 200) {
        let aftersaleProxyPayList = data.aftersaleProxyPayList
        this.returnCampusTotalAmount = aftersaleProxyPayList.reduce((sum, item) => sum + (Number(item.amount) || 0), 0)
      }
    },
    //登录人为财经赛道时，显示出纳审核
    async setCashierCheck(){
      const params = {
        schoolNcId: this.orderTable[0].signSchoolId
      }
      
      const {result} = await getSchoolUserCashierVerifiedUser(params)
      console.log('显示出纳审核', result)
      result && result.map(item => {
        item.userId = item.userId +''
        item.tyUserId = item.tyUserId + ''
      })
      this.cashierCheckList = result?result.concat(mockCashierChecker):mockCashierChecker
    },

    onSureCancel(){
      this.sureVisible = false
    },
    onSureOk(){
      this.onSubmit('dropoutForm')
    },
    // 点击提交
    showSureData(form){
      console.log('this.dropoutForm',this.dropoutForm);
      if(this.loanRefundStuMoney == 0) {
        this.dropoutForm.payType = "0"
      }
      if(this.dropoutForm.payType == 1) {
        // if(!this.dropoutForm.bankAccount || !this.dropoutForm.bankAccountName || !this.dropoutForm.bank || !this.dropoutForm.ncBankAccountPk) {
        //   return this.$message.error('退费方式不能为空！')
        // }
        if (this.dropoutForm.bankAccount == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.bankAccountName == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.bank == "") {
          return this.$message.error('退费方式不能为空！')
        }
        if (this.dropoutForm.ncBankAccountPk == "" ) {
          return this.$message.error('退费方式不能为空！')
        }
      }
      this.$refs[form].validate(async (valid) => {
          if (!valid) return this.$message.error("表单填写有误，请检查")
          if(!this.$refs.annexRef.getAllFiles().length) return this.$message.error("请上传附件")
          if(this.loanRefundStuMoney < 0){
              return this.$message.error("原订单需要学员补款，暂不支持售后申请")
          }
          this.cashierId = await this.getDDUserIdByTyUserId(this.dropoutForm.cashierId)
          if(!this.cashierId) {
            this.$message.error('该出纳人员未配置钉钉id，请重新选择')
            return false
          }
          if(this.approvedRefundableAmount < 0)  return this.$message.error("原订单需要学员补款，暂不支持售后申请")
          let obj = {
            orderNo:this.orderTable[0].orderNo,//售后订单
            goodsName:this.orderTable[0].goodsName,//售后商品
            payType: refundPayTypeMap[this.dropoutForm.payType],//退款方式
            
            refundMoney: this.isLoanOrder() && this.loanRefundStuMoney > 0 ? this.loanRefundStuMoney : this.approvedRefundableAmount, //核定可退金额
            name:this.orderTable[0].stuName,//学员姓名
            mobile:this.dropoutForm.mobile,//学员账号
            idCard:this.dropoutForm.idCard,//身份证
            spec:this.orderTable[0].goodsSpecName,
            receivedMoney: this.classInfo[0].receivedMoney, // 已收金额
            businessId: this.orderTable[0].businessId,//赛道
            type:"dropOut",
            bankAccountName: this.dropoutForm.bankAccountName,//学员开户名
            bank: this.dropoutForm.bank,//学员开户行及网点
            bankAccount: this.dropoutForm.bankAccount,//学员户行账号
            amountReceived: this.orderTable[0].amountReceived,//已收金额
            payTypeId: this.dropoutForm.payType == 2?this.dropoutForm.refundPayType:this.dropoutForm.payType,
            isYQH: this.dropoutForm.isLoanRefund,
            loanRefundMoney: this.dropoutForm.loanRefundMoney,
            loanRefundTime: this.dropoutForm.loanRefundTime,

            appName: this.dropoutForm.payType == 2?this.dropoutForm.appName:'',
            appShop: this.dropoutForm.payType == 2?this.dropoutForm.appShop:''
            // 有钱花贷款end
          }

          if(this.dropoutForm.isLoanRefund) {
            this.loanPlatformList.map(item => {
              if(item.id == this.dropoutForm.loanPlatformId) {
                obj.loanCompanyName = item.accountName
                obj.loanBankName = item.bankName
                obj.loanBankAccount = item.bankAccount
                obj.loanPlatformName = item.loanPlatformName

                this.dropoutForm.loanCompanyName = item.accountName
                this.dropoutForm.loanBankName = item.bankName
                this.dropoutForm.loanBankAccount = item.bankAccount
                this.dropoutForm.loanPlatformName = item.loanPlatformName
              }
            })
          }
          this.sureData = obj;
          localStorage.setItem("sureData", JSON.stringify(this.sureData));
          localStorage.setItem("showCkSingleOption", !this.isCj);
          localStorage.setItem("showCjSingleOption", this.isCj);
          localStorage.setItem("isDoubleProfessional", false);
          localStorage.setItem("showZkMultipleOption", false);
          localStorage.setItem("showZkSingleOption", false);

          this.sureVisible = true

        })
      },
    
    //增加退费计算列
    addDropOut(index) {
      this.receiveSelItem = this.dropOutTableList[index];
      localStorage.setItem("receiveSelItem", JSON.stringify(this.receiveSelItem))
      this._dropOutTableIndex = index
      this.receiveOutVisible = false;
      this.$nextTick(()=> this.receiveOutVisible = true)
    },
    
    onReceiveOutCancel() {
      this.receiveOutVisible = false
      this._dropOutTableIndex = -1
    },

    onReceiveOutOk(val) {
      let dropOutTable = []
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        element.id = ""
        element.inoutType = "1"
        if(this.dropOutTableList[this._dropOutTableIndex].findIndex(it => it.inoutProjectId == element.inoutProjectId) === -1){
          dropOutTable.push(Object.assign({}, element, {
            canDelete: true, 
            orderInoutProjectName: element.orderInoutProjectName || element.name, 
            edit: false,
            receivableMoney: element.receivableMoney || 0,
            receivedMoney: element.receivedMoney || 0,
            deductionMoney: element.deductionMoney || 0,
            refundableMoney: element.refundableMoney || 0
          }))
        }
      }
      this.dropList = dropOutTable
      console.log('dropList', this.dropList);
      this.$set(this.dropOutTableList, this._dropOutTableIndex, [...this.dropOutTableList[this._dropOutTableIndex], ...dropOutTable])
      this.receiveOutVisible = false
      this._dropOutTableIndex = -1
    },
    checkDataNull(){
      for(let i in this.dropOutTable){
        if(this.dropOutTable[i].deductionsList.length <1){
          let fillData = this.dropOutTable[i];
          let obj = {
            orderInoutProjectId:fillData.orderInoutProjectId,
            orderInoutProjectName:fillData.orderInoutProjectName,
            inoutProjectId:"",//扣费项目id
            inoutProjectName:"",//扣费项目名称
            deductionMoney:0,//扣费金额
            refundableMoney:fillData.refundableMoney,
            receivedMoney:fillData.receivedMoney,
            receivableMoney:fillData.receivableMoney
          }
          this.dropOutTable[i].deductionsList.push(obj)
        }
      }
    },
    async onSubmit(form) {
      this.$refs[form].validate(async (valid) => {
        if (!valid) return
        // 退费计算，付款详情数组
        this.checkDataNull();
        let params = await this.createAfterSaleOrder()
        console.log("退费售后单参数",params)
        if(this.submitLoading) return console.log("[退费提交数据中]请稍等！！");
        this.submitLoading = true
        try {
          this.loading = true
          //channel=1 表示是网校
          params.channel = 0;
          params.payType = this.dropoutForm.payType == 2?this.dropoutForm.refundPayType:this.dropoutForm.payType
          if(this.dropoutForm.payType == 1) {
            params.dropOrderNo = ""
            params.dropMobile = ""
            params.dropName = ""
          } else if(this.dropoutForm.payType == 0) {
            params.bankAccountName = ""
            params.bank = ""
            params.bankAccount = ""
            params.ncBankAccountPk = ""
            params.ncAccountName = ""
          }
          
          // 添加全网userId
          params.createrQwId = this.loginUser.userId
          //20231110新增入参
          params.crmDeptId = this.loginUser.deptId
          
          const { code, msg, data } = await this.$fetch("third_submitAfterSaleOrder", params)
          .catch(res =>{
            this.submitLoading = false
            console.log("[退费错误]", res);
            res.msg && this.$message.error(res.msg);
            this.loading = false;
          })
          this.submitLoading = false
          // debugger
          if (code === 200) {
            this.$message.success("售后单生成成功")
            let path = '/aftersale/list'
            let query = {}
            // 已生成售后单就直接跳转售后单详情
            if(Checker.isObject(data) && data.aftersaleId){
              path = '/afterSale/afterSaleDetail'
              query.id = data.aftersaleId
              query.type = aftersaleTypeEnum.dropout
            }
            setTimeout(() => {
              this.$router.replace({
                path,
                query
              })
            }, 300)
          } else {
            this.$message.error(msg)
          }
          this.loading = false
        } catch(err) {
          this.submitLoading = false
          this.loading = false
        }
        this.sureVisible = false
      })
    },


    async createAfterSaleOrder(){
        let aftersalePay =[]
        for(let i in this.dropOutTableList){
          aftersalePay.push(this.setOneDropOut(i))
        }

        let selectedCashier = this.cashierCheckList.filter(item => item.tyUserId == this.dropoutForm.cashierId)[0]

        //订单参数
        const params = {
          ...this.dropoutForm,
          aftersaleOrder: this.orderTable,
          aftersalePay,
          createrId:  this.getTyUser().id,
          creater: this.getTyUser().nickName || this.getTyUser().userName,
          userId: this.orderTable[0].userId,
          
          schoolId: this.orderTable[0].signSchoolId || '',
          schoolName: this.orderTable[0].signSchoolName || '',
          signSchoolName:this.orderTable[0].signSchoolName || '',
          aftersaleSchoolNcid: this.orderTable[0].signSchoolId || '',//售后校区

          balanceAccount:this.dropoutForm.dropMobile, 
          accountName:this.dropoutForm.dropName,
          balanceUserId: this.orderTable[0].userId,
          businessId: this.orderTable[0].businessId,//赛道
          cashierId: this.cashierId,
          cashier: selectedCashier && selectedCashier.userName,
          learnPhoneNumber: this.dropoutForm.stuMoblie,
          channel: 0,
          installmentMode: this.installmentMode,
          appName: this.dropoutForm.payType == 2?this.dropoutForm.appName:'',
          appShop: this.dropoutForm.payType == 2?this.dropoutForm.appShop:'',
          orderOriginType:  Checker.isUndefined(this.orderTable[0].orderOriginType) ? OrderOriginTypeEnum.ty : this.orderTable[0].orderOriginType
        }
        params.aftersaleAttachList = this.$refs.annexRef.getAllFiles()
        if(this._draft && this._draft.id){
          params.id = this._draft.id
        }
        
        return params
    },
    // 自动通过学员账号查询学员信息
    async getCustomeruser(isNotNotifce) {
      console.log("根据手机号查询学生信息", this.dropoutForm);
      if (!this.isPhoneNumber(this.dropoutForm.stuMoblie)) {
        if( isNotNotifce ){
          return;
        }
        return this.$message.warning("学员账号不正确");
      }

      let params = {
        mobile: this.dropoutForm.stuMoblie,
      };

      if (
        this.dropoutForm.stuMoblie &&
        this.dropoutForm.stuMoblie !== this._tmpMoile
      ) {
        let { data, code } = await this.$fetch("getUserByMobile", params);
        //检查学生信息，有就更新，没有就新增
        this._tmpMoile = data && data.mobile; //用于检测手机号是否改变了

        if (code == 200 && data != null) {
          //用户信息已经存在
          if (
            this.dropoutForm.name &&
            this.dropoutForm.name.length &&
            this.dropoutForm.idCard &&
            this.dropoutForm.idCard.length
          ) {
            this.$hqMessageBox({
              message: '是否替换当前学员信息?'
            }).then(() => {
                this.dropoutForm.name = data.realName;
                this.dropoutForm.idCard = data.idCard;
                this.dropoutForm.stuMoblie = data.mobile;
                this.dropoutForm.userId = data.userId; //userId
              })
              .catch(() => {});
          } else {
            this.dropoutForm.name = data.realName;
            this.dropoutForm.idCard = data.idCard;
            this.dropoutForm.stuMoblie = data.mobile;
            this.dropoutForm.userId = data.userId; //userId
          }

          this.hasStuInfo = true;
        } else if (code == 404) {
          return this.$message.error("请求资源不存在");
        } else if (code == 200 && data == null) {
          this.dropoutForm.userId = null;
          this.hasStuInfo = false;
          this._tmpMoile = this.dropoutForm.stuMoblie;
        }
      } else {
        console.log("没查询");
      }
    },
    onOrderModalCancel() {
      this.orderModalVisible = false
    },
    // 选择订单确定回调
    async onOrderModalOk(row) {
      let rows =  Array.isArray(row) ? row : [row]
      this.ids = rows.reduce((accumulator, cur)=> (accumulator ? accumulator + ',' : '') + cur.orderId, '').replace(/^,|,$/g).split(',')
      this.orderModalVisible = false
      this.showloanform = rows[0].installmentType

      await this.getDetailData(this.ids)
      if(this.$route.query.afterSaleId && this.useDraft){
        console.log("从草稿获取收支项目")
        this.setCalcDropout() 
        this.useDraft = false
      }else{
        await this.getCalcDropout(this.ids)
      }
      
    },
    goOrderDetail(row) {
      let path = `${ row.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: row.orderId
        }
      });     
    },   
    //从草稿里获取收支项目自动填充
    setCalcDropout(){
      let data = this._draft
      console.log("草稿内的收支项目>>>>>>>>>>>>>>>>>",data)
      let refundDetail = data ? data.aftersalePay : []//防止没有启课程课包
      this.classInfo = refundDetail //退费信息  退费计算的商品，已收上课等信息
      let dropOutTableList = []
      refundDetail.map(it => {
        let dropOutTable = []
        it.inoutProjects && it.inoutProjects.map(item =>{
          dropOutTable.push({
            ...item,
            refundMoney: it.refundMoney || 0,
            edit: false,
            canDelete: item.inoutType == 1?true:false,
            refundableMoney: item.refundableMoney || 0,
            deductionMoney: item.deductionMoney || 0,
          })
        })
        dropOutTableList.push(dropOutTable)
      })
      this.dropOutTableList = dropOutTableList
    },
    // 获取订单表格数据
    async getDetailData(ids) {
      ids = ids.toString()
      const params = {ids}
      const { data } = await this.$fetch("third_choiceOrderDetailById", params)
      this.orderTable = data.orderLists || [];
      this.orderExtraInfo = data.orderExtraInfo
      console.log('[orderExtraInfo]', data);
      
      if( data.orderLists.length > 0  ){
        if([this.installmentModeEnum.onlineLoan, this.installmentModeEnum.offlineLoan].includes(this.installmentMode)) {
          // isLoanRefund 1 退贷款
          this.dropoutForm.isLoanRefund = 1
          this.$refs.check.set(this.dropoutForm.isLoanRefund)

          // if(data.orderLists[0].loanPayVoucher) {
          this.dropoutForm.loanPlatformId = data.orderLists[0].loanPayVoucher.paymentPlatform
          this.dropoutForm.loanId = data.orderLists[0].loanPayVoucher.paymentPlatform
          this.dropoutForm.loanIouNo = data.orderLists[0].loanPayVoucher.serialNumber
          // }
        }
        let orderItem = data.orderLists[0];
        this.dropoutForm.mobile = orderItem.mobile
        this.dropoutForm.dropMobile = orderItem.mobile
        this.dropoutForm.dropName = orderItem.stuName
        this.dropoutForm.dropOrderNo = orderItem.orderNo
        this.dropoutForm.businessId = orderItem.businessId
        if(orderItem.orderNo && orderItem.mobile){
          this.disabledChangeDropOrderNo = true
        }
        // this.dropoutForm.loanIouNo = orderItem.tradeSerialNumber || ''
        this.loanIouNoDisabled = !!orderItem.tradeSerialNumber

      }

      if(  data.orderExtraInfo.length> 0 ){
          let orderExtraInfoItem = data.orderExtraInfo[0];
          this.dropoutForm.idCard = orderExtraInfoItem.idCard
          this.dropoutForm.stuMoblie =  orderExtraInfoItem.phoneNumber
          this.dropoutForm.name = orderExtraInfoItem.stuName
          this.dropoutForm.learnPhoneNumber = orderExtraInfoItem.learnPhoneNumber
      }
      // 第三方值
      this.dropoutForm.refundPayType = ""

      // 获取第三方数据
      if(data.externalOrderList && data.externalOrderList.length) {
        this.dropoutForm.appShop = data.externalOrderList[0].appShop
        this.dropoutForm.appName = data.externalOrderList[0].appName
        this.dropoutForm.ohterName = this.dropoutForm.appName + (this.dropoutForm.appShop?'-' + this.dropoutForm.appShop:'')
        this.dropoutForm.payMode = data.orderLists[0].payMode
        this.dropoutForm.refundPayType = data.externalOrderList[0].refundPayType
      }
      this.getUserByUserId(this.orderTable[0].userId)

      this.checkCashier()    
    },
    // 获取最新的学员账号
    getUserByUserId(userId) {
      this.$fetch('getUserByUserId', { userId }).then(res => {
        this.dropoutForm.dropMobile = res.data.mobile
        this.dropoutForm.userId = res.data.userId
      })
    },
    // 获取退费计算表格数据
    async getCalcDropout(ids) {
      ids = ids.toString()
      const params = {
        ids,
      }
      let { data, code,msg } = await this.$fetch("thirid_calcDropoutMoneyById", params)
      .catch(res => {
        res.msg && this.$message.error('[获取收支项目] '+ res.msg)
      })
      if (code == 200) {
        if( msg !== '操作成功' ){
          this.$message.warning(msg);
        }

        let refundDetail = data ? data.refundDetail : []//防止没有启课程课包
        if(refundDetail.length){
          refundDetail[0].expand = true
        }
        this.classInfo = refundDetail //退费信息  退费计算的商品，已收上课等信息
        // console.log("退费计算的商品", data)
        let dropOutTableList = []
        refundDetail.map(it => {
          let receivedMoney = 0;//已收金额
          let dropOutTable = []
          it.inoutProjects && it.inoutProjects.map(item =>{
            dropOutTable.push({
              ...item,
              refundMoney: it.refundMoney || 0,
              edit: false,
              canDelete: false,
              refundableMoney: item.refundableMoney || 0,
              deductionMoney: item.deductionMoney || 0,
            })
            receivedMoney += item.receivedMoney//累加已收金额
          })
          dropOutTableList.push(dropOutTable)
          if(Checker.isUndefined(it.receivedMoney) || Checker.isNull(it.receivedMoney)){
            it.receivedMoney = receivedMoney
          }
        })
        this.dropOutTableList = dropOutTableList
      } else {
        this.$message.error(msg)
      }
      console.log("退费表格>>>>>>>", this.dropOutTableList)
      
    },
    loadSaveData() {
      if (this.dropOutIds.length) {
        this.getDetailData(this.dropOutIds)
        this.getCalcDropout(this.dropOutIds)
      }
    },
    // 出纳
    async checkCashier(){
      this.setCashierCheck() //获取出纳列表
      this.cashierFormRules = {
      cashierId: [{required: true,message: "此项必填！！",tirgger: "submit" }],
      ...this.cashierFormRules
      }

      this.drpoutFormRules = this.cashierFormRules
      this.$nextTick(() => {
        this.$refs.dropoutForm.clearValidate()
      })
    },
    // 检测付款方式必填项是否填写
    checkPayTypeChange(value) {
      
      // 学员余额
      if(value == 0) {
        this.drpoutFormRules = {
          dropOrderNo: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          dropName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ...this.cashierFormRules
        }
      // 银行支付
      } else if(value == 1) {
        this.drpoutFormRules = {
          bankAccountName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          bank: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          bankAccount: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ncBankAccountPk: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ...this.cashierFormRules
        }
      // 第三方
      } else if(value == 2) {
        this.drpoutFormRules = {
          ohterName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
          ...this.cashierFormRules
        }
      }
      this.$nextTick(() => {
        this.$refs.dropoutForm.clearValidate()
      })
    },
    // 点击返回按钮
    doBack(){
      this.$hqMessageBox({
        title: "取消退费确认",
        message: '返回将不保留当前退费信息，是否确定取消退费？',
      })
      .then(() => {
        this.$router.back()
      })
      .catch(action => {})
    },
    // 贷款退费输入规则 -- 贷款退费金额 + 学员退费金额不能大于售后订单已收金额
		loanRefundMoneyVerify(value, type){
      if(!value) return false
      this.dropoutForm.loanRefundMoney = (value.match(/^\d*(\.?\d{0,2})/g)[0]) || ""
		},
    getloanPlatformList() {
      this.$fetch("findLoanPlatform").then(res => {
        this.loanPlatformList = res.data
      })
    },
  },


  mounted() {

    this.orderId = this.$route.query.orderId;
    this.afterSaleId = this.$route.query.aftersaleId;
    //如果 this.afterSaleId 存在，那么会走 initData 来获取order数据。
    if( !this.afterSaleId ){
      this.onOrderModalOk({orderId:this.$route.query.orderId})
    }
    //如果存在orderId，才继续
    this.loadSaveData()

    this.getCustomeruser( true );

    this.checkPayTypeChange(this.dropoutForm.payType)

    this.getloanPlatformList()
  },
  computed: {
    ...mapGetters(["getTyUserInfo", "loginUser"]),
    dropOutIds() {
      return this.$store.state.order.dropOutIds
    },
    afterSaleType() {
      return this.$route.query.type
    },
    //核定可退金额
    approvedRefundableAmount(){
      let refundMoney = 0;
      this.dropOutTableList.map(dropOutTable =>{
        let rowFefundMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.refundableMoney) ? 0 : cur.refundableMoney), 0)
        refundMoney += isNaN(+rowFefundMoney) ? 0 : +rowFefundMoney
      })
      return refundMoney
    },
    approvedRefundLabel(){
      const goodsTemplate = this.orderExtraInfo[0] ? this.orderExtraInfo[0].goodsTemplate : ''

      if(this.installmentMode === installmentModeEnum.onlineLoan || this.installmentMode === installmentModeEnum.offlineLoan){
        return '核定可退费合计金额'
      }
      return goodsTemplate === goodsTemplateEnum.cjdp || goodsTemplate === goodsTemplateEnum.cjpt ? '核定可退金额': '核定可退费合计金额'
    },
    installmentMode(){
      if(!this.orderTable[0]) return installmentModeEnum.noLoan
      return this.orderTable[0].installmentMode
    },
    // 贷款信息-退学员金额
    loanRefundStuMoney(){
      return +accSub(this.approvedRefundableAmount , (isNaN(+this.dropoutForm.loanRefundMoney) ? 0 : +this.dropoutForm.loanRefundMoney), 3)
    },
  },
  watch: {
    dropOutTableList: {
      deep: true,
      handler(dropOutTableList){
        console.log('【dropOutTableList】',dropOutTableList, this.timeForReturn)
        this.getBalanceReturnCampus()

        if(dropOutTableList.length){
          let deductionMoney = 0;
          this.dropoutForm.loanOccupiedinterestMoney = 0
          dropOutTableList.map((dropOutTable, dropOutTableIndex) =>{
            let refundMoney = 0
            if(dropOutTable.length){
              dropOutTable.map(row =>{
                if(row.orderInoutProjectName == '利息支出') {
                  this.dropoutForm.loanOccupiedinterestMoney = Number(this.dropoutForm.loanOccupiedinterestMoney) + Number(row.deductionMoney)
                }
                refundMoney += isNaN(+row.refundableMoney) ? 0 : +row.refundableMoney
              })
            }
            if(this.classInfo[dropOutTableIndex]){
              this.classInfo[dropOutTableIndex].refundMoney = refundMoney
            }

            let rowDeductionMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.deductionMoney) ? 0 : cur.deductionMoney), 0)
            deductionMoney += isNaN(+rowDeductionMoney) ? 0 : +rowDeductionMoney
          })
          // 合计扣费金额
          this.allDeductionMoney = deductionMoney || 0
        }
      }
    }
  }
}
</script>
<style lang="scss">
input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button{
  -webkit-appearance: none !important; 
}
input[type="number"]{
  -moz-appearance:textfield;/* firefox */
}
</style>
<style lang="scss" scoped>
@import "./index.scss";
</style>